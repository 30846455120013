import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  BooleanField,
  Filter,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  CardActions,
  CreateButton,
  SelectInput,
  FunctionField,
  ReferenceInput
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";
import ExpandLess from "@material-ui/icons/ExpandLess";


export const ClientIcon = Icon;

const ProjectTitle = ({ record }) => {
  return <span>Project {record ? `"${record.name}"` : ""}</span>;
};

const formatDepartment = dep => {
  if (dep === "2") return "Development";
  if (dep === "4") return "MKTG";
};

const FlatrateFilter = props => (
  <Filter {...props}>
    <SelectInput source="department" choices={[
      { id: '2', name: 'Development' },
      { id: '4', name: 'MKTG' },
    ]} />

    <SelectInput source="invoice" label="Invoice Interval" choices={[
      { id: 'Yearly', name: 'Yearly' },
      { id: 'Monthly', name: 'Monthly' },
      { id: 'Once', name: 'Once' },
    ]} />

    <ReferenceInput
      label="Project"
      sort={{ field: "id", order: "ASC" }}
      source="project.id"
      reference="projects"
      perPage={300}
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      label="Client"
      sort={{ field: "id", order: "ASC" }}
      source="customer.id"
      reference="customers"
      filterToQuery={searchText => ({ name: searchText })}
      allowEmpty
    >
      <AutocompleteInput optionText="companyName" />
    </ReferenceInput>
  </Filter>
);


export const FlatrateList = props => (
  <List {...props} bulkActionButtons={false} filters={<FlatrateFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="project.name" label="Project" />
      <TextField source="customer.companyName" label="Customer" />
      <TextField source="department" label="Dep. Code" />
      <FunctionField label="Department" render={record => formatDepartment(record.department)} />
      <TextField source="invoice" label="Invoice Interval"/>
      <TextField source="type" />
      <TextField source="price" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const FlatrateCreate = props => (
  <Create title="Create a Note" {...props} >
    <SimpleForm redirect="list">
      <SelectInput source="type" choices={[
        { id: 'Monthly hosting and SLA', name: 'Monthly hosting and SLA' },
        { id: 'Yearly hosting', name: 'Yearly hosting' },
        { id: 'Flatrate', name: 'Flatrate' },
        { id: 'Other', name: 'Other' },
      ]} />
      <TextInput source="name" />
      <TextInput source="price" />

      <ReferenceInput
        label="Client"
        sort={{ field: "id", order: "ASC" }}
        source="customer.id"
        reference="customers"
        perPage={1000}
        filterToQuery={searchText => ({ name: searchText })}
        allowEmpty
      >
        <AutocompleteInput optionText="companyName" />
      </ReferenceInput>

      <ReferenceInput
        label="Project"
        sort={{ field: "id", order: "ASC" }}
        source="project.id"
        reference="projects"
        perPage={1000}
        filterToQuery={searchText => ({ name: searchText })}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <SelectInput source="invoice" choices={[
        { id: 'Yearly', name: 'Yearly' },
        { id: 'Monthly', name: 'Monthly' },
        { id: 'Once', name: 'Once' },
      ]} />

      <SelectInput source="department" choices={[
        { id: '2', name: 'Development' },
        { id: '4', name: 'MKTG' },
      ]} />

      <SelectInput source="ident" choices={[
        { id: '26', name: 'Marketing' },
        { id: '4', name: 'Ostalo' },
        { id: '13', name: 'Zalaganje sredstev' },
        { id: '10', name: 'Marketing ure' },
        { id: '33', name: 'Gostovanje' },
        { id: '34', name: 'Nakup ali podaljšanje domene' },
        { id: '44', name: 'Razvoj' },
      ]} />

    </SimpleForm>
  </Create>
);

export const FlatrateEdit = props => (
  <Edit title="Edit Note" actions={<CardActions />} {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={[
        { id: 'Monthly hosting and SLA', name: 'Monthly hosting and SLA' },
        { id: 'Yearly hosting', name: 'Yearly hosting' },
        { id: 'Flatrate', name: 'Flatrate' },
        { id: 'Other', name: 'Other' },
      ]} />

      <TextInput source="name" />
      <TextInput source="price" />

      <ReferenceInput
        label="Client"
        sort={{ field: "id", order: "ASC" }}
        source="customer.id"
        reference="customers"
        perPage={1000}
        filterToQuery={searchText => ({ name: searchText })}
        allowEmpty
      >
        <AutocompleteInput optionText="companyName" />
      </ReferenceInput>

      <ReferenceInput
        label="Project"
        sort={{ field: "id", order: "ASC" }}
        source="project.id"
        reference="projects"
        perPage={1000}
        filterToQuery={searchText => ({ name: searchText })}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <SelectInput source="invoice" choices={[
        { id: 'Yearly', name: 'Yearly' },
        { id: 'Monthly', name: 'Monthly' },
        { id: 'Once', name: 'Once' },
      ]} />

      <SelectInput source="department" choices={[
        { id: '2', name: 'Development' },
        { id: '4', name: 'MKTG' },
      ]} />

      <SelectInput source="ident" choices={[
        { id: '26', name: 'Marketing' },
        { id: '4', name: 'Ostalo' },
        { id: '13', name: 'Zalaganje sredstev' },
        { id: '10', name: 'Marketing ure' },
        { id: '33', name: 'Gostovanje' },
        { id: '34', name: 'Nakup ali podaljšanje domene' },
        { id: '44', name: 'Razvoj' },
      ]} />

    </SimpleForm>

  </Edit>
);